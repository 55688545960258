<template>
    <div>
        <ConsolidationFileView />
        <ConsolidationView />
        <ReportView />
    </div>
</template> 

<script>
import ConsolidationFileView from './components/ConsolidationFileView.vue'
import ConsolidationView from './components/ConsolidationView/index.vue'
import ReportView from './components/ReportView.vue'
export default {
    components: {
        ConsolidationFileView,
        ConsolidationView,
        ReportView
    }
}
</script>